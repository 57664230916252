<template>
    <div
        class="bg-dark w-full flex px-120px py-24px gap-20px leading-24px items-center ipad:(flex-col gap-32px px-24px py-48px items-center) mobile:(flex-col gap-32px px-16px py-32px items-center)"
    >
        <span>
            <img class="h-24px" src="../../../img/home_log.svg" />
        </span>
        <span v-for="{ term, link } in footer.terms">
            <!-- <a class="link text-base-16px font-400">{{ item }}</a> -->
            <router-link class="link text-base-16px font-400" :to="link">{{ term }}</router-link>
        </span>
        <span v-if="$route.path === '/'" class="sf_18 text-base-16px text-light">
            {{ footer.contact }}
        </span>
        <span class="sf_18 text-base-16px text-light">{{ footer.copyRight }}</span>
    </div>
</template>
<script>
export default {
    name: 'footerBar',
    props: {
        footer: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {}
    }
}
</script>
<style></style>
